import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../services/user-service';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset errors
    let hasError = false;

    // Validate username
    if (username.trim() === '') {
      toast.error('Please enter your username.');
      hasError = true;
    }

    // Validate password
    if (password.trim() === '') {
      toast.error('Please enter your password.');
      hasError = true;
    }

    if (hasError) return;

    try {
      const data = { username, password };
      await login(data);
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      toast.error('Server error. Please try again later.');
    }

    // Admin mock logic (if required)
    if (username === 'admin' && password === 'admin123') {
      try {
        navigate('/dashboard');
      } catch (error) {
        console.error(error);
        toast.error('Server error. Please try again later.');
      }
    }
  };

  return (
    <div className="text-center w-full">
      {/* Toast Container */}
      <ToastContainer 
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <h1 className="text-black text-2xl font-bold font-Poppins mb-6">
        <b>Innovativ<span style={{ color: 'rgb(234, 209, 0, 1)' }}>Soft</span></b>
      </h1>
      <h2 className="text-2xl font-bold mb-5">Login</h2>
      <form className="py-16" onSubmit={handleSubmit}>
        <div className="mb-32">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 text-left"
            style={{ marginTop: '-3rem' }}
          >
            Username
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type Your Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 text-left"
            style={{ marginTop: '-6.5rem' }}
          >
            Password
          </label>
          <input
            type="password"
            className="shadow appearance-none border rounded w-64 h-10 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Type Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-center mb-4">
          <button
            className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-6 rounded-full focus:outline-none"
            type="submit"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
