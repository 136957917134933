import { myAxios } from './helper';

// Login function
export const login = (user) => {
  return myAxios.post('/account/login', user)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};



// ////////////////////////////////////////////////////
// // Fetch Previous Quotation Number
// export const fetchPreviousQuotationNumber = async () => {
//   try {
//     const response = await myAxios.get('/api/quotation/previous-number');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching previous quotation number:', error.response.data.message || error.message);
//     throw error;
//   }
// };

// // Generate New Quotation Number
// export const generateNewQuotationNumber = async () => {
//   try {
//     const response = await myAxios.post('/api/quotation/new-number');
//     return response.data;
//   } catch (error) {
//     console.error('Error generating new quotation number:', error.response.data.message || error.message);
//     throw error;
//   }
// };

// // Submit Quotation
// export const submitQuotation = async (quotationData) => {
//   try {
//     const response = await myAxios.post('/api/quotation', quotationData);
//     return response.data;
//   } catch (error) {
//     console.error('Error submitting quotation:', error.response.data.message || error.message);
//     throw error;
//   }
// };

//////////////////////////////////////////////////////

// // Add Material
// export const addMaterial = async (materialData) => {
//   try {
//     const response = await myAxios.post('/api/materials/save');
//     return response.data;
//   } catch (error) {
//     console.error('Error adding material:', error.response?.data?.message || error.message);
//     throw error;
//   }
// };

// // fecthmatrial Material
// export const fetchMaterialDetails = async () => {
//   try {
//     const response = await myAxios.get('/api/materials');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching material details:', error.response.data.message || error.message);
//     throw error;
//   }
// };

// export const DeleteMaterialDetails = async () => {
//   try {
//     const response = await myAxios.get('/api/materials/delete');
//     return response.data;
//   } catch (error) {
//     console.error('Error Delete fetching material details:', error.response.data.message || error.message);
//     throw error;
//   }
// };

///////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////



// // Delete Material
// export const deleteMaterial = async (id) => {
//   try {
//     await myAxios.delete('/api/materials/${id}');
//   } catch (error) {
//     console.error('Error deleting material:', error.response?.data?.message || error.message);
//     throw error;
//   }
// };

// // Submit Material (If this is meant for something specific, e.g., submitting a form, you might want to handle it differently)
// export const submitMaterial = async (materialData) => {
//   try {
//     const response = await myAxios.post('/api/materials/submit', materialData);
//     return response.data;
//   } catch (error) {
//     console.error('Error submitting material:', error.response?.data?.message || error.message);
//     throw error;
//   }
// };


//purchase vechicle

export const purchaseVehical = async (purchaseData) => {
  try {
    const response = await myAxios.post('/api/purchase', purchaseData);
    return response.data;
  } catch (error) {
    console.error('Error submitting material:', error.response?.data?.message || error.message);
    throw error;
  }
};

// /////////////////////////////// Mainatence Vechical /////////////////////////////////////////////
// // Mainatence Vechical
// export const SaveMaintainVehical = async (mainatinData) => {
//   try {
//     const response = await myAxios.post('/api/maintenance/save');
//     return response.data;
//   } catch (error) {
//     console.error('Error Mainatine material:', error.response?.data?.message || error.message);
//     throw error;
//   }
// }

// // delete maintain 

// export const DeleteMaintainVehical = async (mainatinData) => {
//   try {
//     const response = await myAxios.post('api/maintenance/delete');
//     return response.data;
//   } catch (error) {
//     console.error('Error Mainatine material:', error.response?.data?.message || error.message);
//     throw error;
//   }
// }


// // update maintain 

// export const UpdateMaintainVehical = async (mainatinData) => {
//   try {
//     const response = await myAxios.post('api/maintenance/update');
//     return response.data;
//   } catch (error) {
//     console.error('Error Update Mainatine material:', error.response?.data?.message || error.message);
//     throw error;
//   }
//}
///////////////////////////////////////////////////////////////

// Search material and city
// export const searchMaterials = async (searchData) => {
//   try {
//     const response = await myAxios.get('/api/search', {
//       params: {
//         city: searchData.city,
//         material: searchData.material,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error searching materials:', error.response?.data?.message || error.message);
//     throw error;
//   }
// };
