import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import'./css/header.css'

const Header = () => {
  const handleLogout = () => {
    // Implement logout functionality here
  };

  return (
    <nav className="nav-h">
      <div className="header-name">InnovativSoft Stone Crusher ERP</div>
      <div className="header-div">
        <div className="username-img">
          <FaUserCircle size={24} />
          <span>Username</span>
        </div>
        <button
          onClick={handleLogout}
          className="logout-btn-h"
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Header;
