import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/Login";
import DashboardPage from "./components/DashboardPage";
import NewQuotation from "./components/NewQuotation";
import ViewQuotation from "./components/ViewQuotation";
import Home from "./components/Home";
import './App.css'
import './components/css/dashboard.css'

function App() {
  return (
    <div className="classname">
      {/* <Sidebar /> */}
      <div className="root-main">
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/new-quotation" element={<NewQuotation />} />
            <Route path="/viewquotation" element={<ViewQuotation />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
