import React, { useState, useEffect } from 'react';
import { fetchAllQuotations } from '../services/ViewQuotation'; 
import './css/ViewQuotation.css'
import './css/dashboard.css'
import Header from './Header';
import Sidebar from './SideBar';
import { FaEdit, FaTrash } from 'react-icons/fa';

const ViewQuotation = () => {
  const [quotations, setQuotations] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]); 
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchNumber, setSearchNumber] = useState(''); 
  const [searchDate, setSearchDate] = useState(''); 
  const [searchReference, setSearchReference] = useState(''); 
  // const [currentQuotation, setCurrentQuotation] = useState(null);


  useEffect(() => {
    const getQuotations = async () => {
      try {
        const data = await fetchAllQuotations(); 
        setQuotations(data); 
        setFilteredQuotations(data);
      } catch (error) {
        console.error('Error fetching quotations:', error.message);
      }
    };

    getQuotations();
  }, []);

  // const handleEditClick = (quotation) => {
  //   setCurrentQuotation({ ...quotation });
  //   setIsModalOpen(true);
  // };

  // const handleModalClose = () => {
  //   setIsModalOpen(false);
  //   setCurrentQuotation(null);
  // };

  // const handleInputChange = (field, value) => {
  //   setCurrentQuotation((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };

  // const handleSave = () => {
  //   setQuotations((prev) =>
  //     prev.map((quotation) =>
  //       quotation._id === currentQuotation._id ? currentQuotation : quotation
  //     )
  //   );
  //   setFilteredQuotations((prev) =>
  //     prev.map((quotation) =>
  //       quotation._id === currentQuotation._id ? currentQuotation : quotation
  //     )
  //   );
  //   handleModalClose();
  // };

  const handleSearch = () => {
    const filtered = quotations.filter((quotation) => {
      const matchesNumber = searchNumber
        ? quotation.quotationNumber.toString().includes(searchNumber)
        : true;
      const matchesDate = searchDate
        ? new Date(quotation.quotationDate).toLocaleDateString() ===
          new Date(searchDate).toLocaleDateString()
        : true;
      const matchesReference = searchReference
        ? quotation.reference.toLowerCase().includes(searchReference.toLowerCase())
        : true;

      return matchesNumber && matchesDate && matchesReference;
    });

    setFilteredQuotations(filtered);
  };

  const clearSearch = () => {
    setSearchNumber('');
    setSearchDate('');
    setSearchReference('');
    setFilteredQuotations(quotations);
  };

  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
          <Sidebar />
          <div className='main-view'>
            <div className='v-first-section'>
              <div className='v-first-row'>
                <button>Back</button>
              </div>
              <div className='v-second-row'>
                <button onClick={clearSearch}>Clear</button>
              </div>
            </div>
            <div className='v-second-section'>
              <input 
                className='v-second-section-input' 
                placeholder='Quotation No.'
                type='text'
                value={searchNumber}
                onChange={(e) => setSearchNumber(e.target.value)}
              />
              <input 
                className='v-second-section-input' 
                placeholder='Quotation Date'
                type='date'
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
              />
              <input 
                className='v-second-section-input' 
                placeholder='Refrence'
                type='text'
                value={searchReference}
                onChange={(e) => setSearchReference(e.target.value)}
              />
              <button 
                className='v-second-section-button'
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <table className='view-table-v'>
              <thead className='view-main-table-v'>
                <tr className='view-main-table-tr'>
                  <th className="first-th-v">Quotation No.</th>
                  <th className="first-th-v">Quotation Date</th>
                  <th className="first-th-v">Party Name</th>
                  <th className="first-th-v">Materials</th>
                  <th className="first-th-v">Total Quantity</th>
                  <th className="first-th-v">Total Amount</th>
                  <th className="first-th-v">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredQuotations.map((quotation) => (
                  <tr className='view-main-table-tr-s' key={quotation._id} >
                    <td className="second-td-v">{quotation.quotationNumber}</td>
                    <td className="second-td-v">{new Date(quotation.quotationDate).toLocaleDateString()}</td>
                    <td className="second-td-v">{quotation.partyName}</td>
                    <td className="second-td-v">
                      {quotation.items.map((item, index) => (
                        <div key={index}>{item.material}</div>
                      ))}
                    </td>
                    <td className="second-td-v">
                    {quotation.items.map((item, index) => (
                        <div key={index}>{item.qty}</div>
                      ))}
                    </td>
                    <td className="second-td-v">
                      {quotation.items.reduce((total, item) => total + item.amount, 0).toFixed(2)}
                    </td>
                    <td className="second-td-v">
                      <button
                        type="button"
                        // onClick={() => handleEditClick(quotation)}
                        className="edit-r-icon-v"
                        id='edit-r-icon-v'
                      >
                        <FaEdit />
                      </button>
                      <button
                        type="button"
                        // onClick={() => handleDelete(index)}
                        className="delete-r-icon-v"
                        id='delete-r-icon-v'
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      </div>
      {/* {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Edit Quotation</h3>
            <input
              type="text"
              value={currentQuotation.quotationNumber}
              onChange={(e) => handleInputChange('quotationNumber', e.target.value)}
              placeholder="Quotation Number"
            />
            <input
              type="date"
              value={new Date(currentQuotation.quotationDate).toISOString().slice(0, 10)}
              onChange={(e) => handleInputChange('quotationDate', e.target.value)}
              placeholder="Quotation Date"
            />
            <input
              type="text"
              value={currentQuotation.partyName}
              onChange={(e) => handleInputChange('partyName', e.target.value)}
              placeholder="Party Name"
            />
            <button onClick={handleSave}>Save</button>
            <button onClick={handleModalClose}>Cancel</button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ViewQuotation;
