// src/services/ViewQuotation.js
import { myAxios } from './helper';

// Fetch all quotations for viewing
export const fetchAllQuotations = async () => {
  try {
    const response = await myAxios.get('/api/quotations');
    return response.data;
  } catch (error) {
    console.error('Error fetching quotations:', error.response?.data?.message || error.message);
    throw error;
  }
};