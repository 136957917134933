import React, { useRef, useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="relative p-8 rounded-lg shadow-lg bg-white"
        style={{ width: '333px', height: '486px', borderRadius: '10px' }}
      >
        {/* <button
          className="absolute top-10 right-10 text-white mt-20 mr-20 text-xl font-extrabold"
          onClick={onClose}
        >
          X
        </button> */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
