import React from 'react';
import Sidebar from './SideBar';
import Header from './Header';
import './css/dashboard.css'


const DashboardPage = () => {
  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
        <Sidebar />
        <div className="dashboard-h">
          <h1 className="dashboard-text">Welcome to the Dashboard</h1>
          {/* Add your dashboard content here */}
        </div>
      </div>
    </div>
    
  );
};

export default DashboardPage;

