import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { FaAlignJustify, FaAngleDoubleLeft,FaRecycle , FaAngleDoubleRight,  FaTruckArrowRight,FaAngleLeft} from "react-icons/fa";
import { BsTruck } from "react-icons/bs";
import { TfiHeadphone } from "react-icons/tfi";
import { VscFile } from "react-icons/vsc";
import { MdEmojiTransportation } from "react-icons/md";
import { GrVmMaintenance } from "react-icons/gr";
import { TbReportAnalytics } from "react-icons/tb";
import { LuUser2 } from "react-icons/lu";
import { GiHistogram } from "react-icons/gi";
import { PiTagBold } from "react-icons/pi";
import { HiOutlineCurrencyRupee ,  HiOutlineDocumentChartBar} from "react-icons/hi2";
import { BsSave2 } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx"
import { RiBillLine } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdCall } from "react-icons/io";




const Sidebar = () => {
  const [isQuotationOpen, setIsQuotationOpen] = useState(false);
  const [isSalesopen,setIsSalesopen]  = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Start with sidebar open
  const [showTooltip, setShowTooltip] = useState();
  const [tooltipTimeout, setTooltipTimeout] = useState(null);

    const handleMouseEnter = () => {
        const timeoutId = setTimeout(() => {
            setShowTooltip(true);
        }, 500); 
        setTooltipTimeout(timeoutId);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
        if (tooltipTimeout) {
            clearTimeout(tooltipTimeout); 
        }
    };




  const closeModal = () => {
    setShowCard(false);
  };

  const toggleCard = () => {
    setShowCard(!showCard);
  };

  const toggleQuotationDropdown = () => {
    setIsQuotationOpen(!isQuotationOpen);
  };

  const toggleSales=()=>{
    setIsSalesopen(!isSalesopen);
    
  }
  const handlesupplier = ()=>{
    Navigate('/')
  }

  const handleviewquotation = () =>{
    Navigate('/viewquotation');
  }

  const handlenewbill = ()=>{
    Navigate('/Newbill');
  }

  const Sidebarhandle = () => {
    setIsSidebarOpen(!isSidebarOpen); 
  };
  
  return (
    <div className="flex sidebar">                
      <div className={`bg-gray-800 text-white ${isSidebarOpen ? 'w-60' : 'w-0'} transition-width duration-300 overflow-hidden`}>
      {!isSidebarOpen && (
        <button
          className="absolute  left-4 bg-gray-800 text-white p-2"
          style={{marginTop:"-60px", marginLeft:"1.6%"}}
          onClick={Sidebarhandle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* {showTooltip && (
            <div className="absolute left-16    bg-yellow-500 text-white text-xs rounded " style={{marginBottom:"5%"}}>
              Open  Sidebar
            </div>
          )} */}
         <FaAlignJustify size={19}/> 
        </button>
      )}
        <div className="p-4  text-center " style={{backgroundColor:"black"}}>
          <h2 className="flex items-center text-xl font-semibold mt-2">
          <span className="text-2xl mr-2 cursor-pointer" >
            </span>
            <span className="flex-grow" style={{marginLeft:"-2%"}}><span className="bg-black">Innovativ</span>
              <span className=" text-white">Soft</span></span>
            <span className="text-2xl mr-2 cursor-pointer " onClick={Sidebarhandle} style={{marginLeft:"4%"}}>
              <FaAngleDoubleLeft size={20}
            />        
            </span> 
          </h2>
        </div>
        <ul className="mt-">
          <li className="px-3 py-4 hover:bg-gray-700 flex items-center mt-0" style={{paddingLeft:"19%", }}>
            <Link to="/dashboard" className='flex items-center ' style={{marginTop:"0.2rem"}}>
            <RxDashboard  size={18} className='mr-3'/>
            Dashboard     </Link>
          </li>
          <li className=" px-4 py-4 hover:bg-gray-700"  style={{paddingLeft:"19%"}}>
            <button className="w-full text-left focus:outline-none  flex items-center" onClick={toggleQuotationDropdown}>
            <RiBillLine size={18}   className=" mr-3"/>
              Quotation &nbsp;< IoIosArrowDown size={14}  style={20}/>
            </button>
            {isQuotationOpen && (
              <ul className="absolute left-7 bg-gray-700" width={"58%"}>
                <li className="px-12 py-4 hover:bg-gray-600">
                  <Link to="/new-quotation" className='flex items-center'>
                  <HiOutlineDocumentChartBar size={18}   className=" mr-3"/>New Quotation</Link>
                </li>
                <li className="px-12 py-4 hover:bg-gray-600">
                  <Link to="/viewquotation" className='flex items-center' onClick={handleviewquotation}>
                   <RiBillLine size={18}   className=" mr-3"/>View Quotation</Link> 
                </li>
              </ul>
            )}
          </li>
         <li className="px-4 py-4  hover:bg-gray-700" style={{paddingLeft:"19%"}} onClick={toggleSales}>
           <Link to="/sales" className='flex items-center'>
           <HiOutlineCurrencyRupee size={18} className='mr-3'/>Sales
           <  IoIosArrowDown size={14}  className="ml-3" style={{ fontSize: '20px' }}/></Link>
            {isSalesopen && (
              <ul className="absolute left-7 bg-gray-700 " style={{ width: '12.5%' }}>
                <li className="px-4 py-2 hover:bg-gray-600">
                  <Link to="/new-quotation" className='flex items-center'>
                  <HiOutlineDocumentChartBar size={18}   className=" mr-3"/>New Sales</Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-600">
                  <Link to="/quotation/viewquotation" className='flex items-center' onClick={handleviewquotation}>
                   <RiBillLine size={18}   className=" mr-3"/>View Sales</Link> 
                </li>
              </ul>
            )}
        </li>
         <li className="px-4 py-4 hover:bg-gray-700" style={{paddingLeft:"19%"}}>
           <Link to="/Purchases" className='flex items-center'>
           <VscFile size={18} className='mr-3'/>
           Add</Link>
         </li>
         <li className="px-4 py-4 hover:bg-gray-700" style={{paddingLeft:"19%"}}>
           <Link to="/purchase" className='flex items-center'>
           <PiTagBold size={18} className='mr-3'/>
           Purchase</Link>
         </li>
         <li className="px-4 py-4 hover:bg-gray-700 " style={{paddingLeft:"19%"}}>
           <Link to="/customer" className="flex items-center">
           <VscFile size={18} className="mr-3"/>
           Customer</Link>
         </li>
         <li className="px-4 py-4  hover:bg-gray-700" style={{paddingLeft:"19%"}}>
           <Link to="/supplier" className='flex items-center'>< BsTruck size={18} className="mr-4"/>
           Supplier
           </Link>
         </li>
         <li className="px-4 py-4 hover:bg-gray-700" style={{paddingLeft:"19%"}}>
           <Link to="/Material" className="flex items-center">
           < BsSave2  size={18} className="mr-4"/>
           Material</Link>
         </li>
         <li className="px-4 py-4 hover:bg-gray-700" style={{paddingLeft:"19%"}}>
           <Link to="/vehicle" className='flex items-center'>
           < BsTruck size={18} className="mr-4"/>
           Vehicle</Link>
         </li>
         <li className="px-4 py-4 hover:bg-gray-700 flex items-center" style={{paddingLeft:"19%"}}>
         <GrVmMaintenance size={18} className='mr-3' />
           <Link to="/maintenance">Maintenance</Link>
         </li>
         <li className="px-4 py-4 hover:bg-gray-700 flex items-center" style={{paddingLeft:"19%"}}>
          <MdEmojiTransportation size={20}  className='mr-3'/>
           <Link to="/transport">Transport</Link>
         </li>
        <li className="px-4 py-4 hover:bg-gray-700 " style={{paddingLeft:"19%"}}>
           <Link to="/recycle-bin"  className="flex items-center">
           <FaRecycle size={18} className='mr-3'/> 
            Recycle Bin</Link>
         </li>
         <li className="px-4 py-4  hover:bg-gray-700 " style={{paddingLeft:"19%"}}>
         <Link to="/report" onClick={toggleCard} className="flex items-center">
         <TbReportAnalytics size="18" className="mr-3" />
         Report
           </Link>
         </li>
          {/* Add other menu items here */}
          <li className="px-4 py-4 hover:bg-gray-700" style={{ paddingLeft: "19%" }}>
          <Link to="/support" onClick={toggleCard} className="flex items-center">
            <TfiHeadphone size="18" className="mr-3" />
            Support
          </Link>
            {showCard && (
              <div
                className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-12 z-50"
                onClick={closeModal}
              >
                <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full relative" onClick={(e) => e.stopPropagation()}>
                  <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={closeModal}>&times;</button>
                  <h1 className="text-2xl font-bold text-center">
                    <span className="text-black">Innovativ</span>
                    <span className="text-yellow-500">Soft</span>
                  </h1>
                  <div className="mt-4 text-center">
                  <p className="text-black font-bold">📧 Email ID:contact@innovativsoft.com</p>
                    <p className="text-black font-bold"><img src=""/>Contact No: +91 000-000-0000</p>
                    <p className="text-black font-bold"> 📍 Location: Ambegaon, Pune</p>
                  </div>
                </div>
              </div> 
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;


