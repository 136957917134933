  import { myAxios } from './helper';

  // Fetch Previous Quotation Number
  export const fetchPreviousQuotationNumber = async () => {
    try {
      const response = await myAxios.get('/api/quotation/previous-number');
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Error fetching previous quotation number:', error.response?.data?.message || error.message);
      throw error;
    }
  };

  // Generate New Quotation Number
  export const generateNewQuotationNumber = async () => {
    try {
      const response = await myAxios.get('/api/quotation/new-number');
      console.log(response)
      return response.data;
      
    } catch (error) {
      console.error('Error generating new quotation number:', error.response?.data?.message || error.message);
      throw error;
    }
  };

  // Submit Quotation
  export const submitQuotation = async (quotationData) => {
    try {
      console.log('Submitting quotation data to backend:', quotationData)
      const response = await myAxios.post('/api/quotation/create', quotationData);
      return response.data;
    } catch (error) {
      console.error('Error submitting quotation:', error.response?.data?.message || error.message);
      throw error;
    }
  };

  // Fetch all Quotations
  export const fetchAllQuotations = async () => {
    try {
      const response = await myAxios.get('/api/quotation//view-quotations');
      return response.data;
    } catch (error) {
      console.error('Error fetching quotations:', error.response?.data?.message || error.message);
      throw error;
    }
  };

    