import React, { useState, useEffect } from 'react'
import './css/Quotation.css'
import './css/dashboard.css'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Sidebar from './SideBar';
import Header from './Header';
import  {fetchPreviousQuotationNumber,generateNewQuotationNumber,submitQuotation} from '../services/Quotation';

function NewQuotation() {
  const navigate = useNavigate();
  const [quotationNumber, setQuotationNumber] = useState('');
  const [quotationDate, setQuotationDate] = useState(new Date());
  const [reference, setReference] = useState('');
  const [partyName, setPartyName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [deliveryPlace, setDeliveryPlace] = useState('');
  const [tripSelection, setTripSelection] = useState('');
  const [material, setMaterial] = useState('');
  const [qty, setQty] = useState('');
  const [rate, setRate] = useState('');
  const [unit, setUnit] = useState('');
  const [amount, setAmount] = useState('');
  const [items, setItems] = useState([]);
  const [withGST, setWithGST] = useState(true);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [materialOptions, setMaterialOptions] = useState([]);


  useEffect(() => {
    const fetchQuotationNumber = async () => {
      try {
        const result = await generateNewQuotationNumber();
        setQuotationNumber(result.quotationNumber);
      } catch(error) {
        console.log('Error fetching number', error);
      }
    };
    fetchQuotationNumber();
  },[])

  useEffect(() => {
    const calculateAmount = () => {
      if (qty && rate) {
        const calculatedAmount = parseFloat(qty) * parseFloat(rate);
        setAmount(calculatedAmount.toFixed(2));
      } else {
        setAmount('');
      }
    };
    calculateAmount();
  }, [qty, rate]);

  // useEffect(() => {
  //   const fetchMaterials = async () => {
  //     try {
  //       const materials = await fetchMaterialDetails();
  //       setMaterialOptions(materials);
  //     } catch (error) {
  //       console.error('Error fetching materials:', error);
  //     }
  //   };

  //   fetchMaterials();
  // }, []);

  
  

  const addItem = () => {
    const newItem = { material, qty, rate, unit, amount };

    if (editIndex !== null) {
      const updatedItems = [...items];
      updatedItems[editIndex] = newItem;
      setItems(updatedItems);
      setEditIndex(null);
      setMessage('Material updated successfully');
    } else {
      setItems([...items, newItem]);
      setMessage('Material added successfully');
    }

    setMaterial('');
    setQty('');
    setRate('');
    setUnit('');
    setAmount('');
    setTimeout(() => setMessage(''), 3000); 
  };

  const resetForm = () => {
    setMaterial('');
    setQty('');
    setRate('');
    setUnit('');
    setAmount('');
    setEditIndex(null);
  };

  const calculateTotalAmount = () => {
    const total = items.reduce((total, item) => total + parseFloat(item.amount), 0);
    const gstAmount = withGST ? total * 0.05 : 0;
    const totalAmountWithGST = total + gstAmount;
    return { total, gstAmount, totalAmountWithGST };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const quotationData = {
      quotationNumber,
      quotationDate: quotationDate instanceof Date ? quotationDate : new Date(quotationDate),
      reference,
      partyName,
      contactNumber,
      deliveryPlace,
      tripSelection,
      items,
      description,
      withGST
    };
    console.log('Submitting clean data:', quotationData);

    try {
      const result = await submitQuotation(quotationData);
      console.log('Quotation submitted successfully:', result);
    } catch (error) {
      console.error('Error submitting quotation:', error);
    }
  };

  const handleClose = () => navigate('/dashboard');

  const handleEdit = (index) => {
    const itemToEdit = items[index];
    setMaterial(itemToEdit.material);
    setQty(itemToEdit.qty);
    setRate(itemToEdit.rate);
    setUnit(itemToEdit.unit);
    setAmount(itemToEdit.amount);
    setEditIndex(index);
  };

  const handleDelete = (index) => setItems(items.filter((_, i) => i !== index));

  const { total, gstAmount, totalAmountWithGST } = calculateTotalAmount();





  return (
    <div className="dashboard-root">
      <Header />
      <div className="main-d">
          <Sidebar />
        <div className='qmain'>
          <div className='qfirst-section'>
            <div className='top-section'>
              <div className='top-f-h'>
                <h1 className='add-quote'>Add Quotation</h1>
              </div>
              <div className="qright-section">
                <div className="qradio-horizontal">
                  <input
                    className="qinput-field-r"
                    type="radio"
                    id="withGST"
                    checked={withGST}
                    onChange={() => setWithGST(true)}
                  />
                  <label htmlFor="withGST" className="qgst">
                    With GST
                  </label>
                  <input
                    className="qinput-field-r"
                    type="radio"
                    id="withoutGST"
                    checked={!withGST}
                    onChange={() => setWithGST(false)}
                  />
                  <label htmlFor="withoutGST" className="qgst">
                    Without GST
                  </label>
                </div>
              </div>
              <div className='top-s-h'>
                <div className='qclose-btn'>
                  <button type='button' onClick={handleClose}>Close</button>
                </div>
              </div>
            </div>
            <div className='qfirst-row'>
              <div className='qfirst-half'>
                <div className='qquotition-no'>
                  <input 
                    className='qinput-field' 
                    type='text' 
                    placeholder='Quotition No'
                    id="partyName"
                    value={quotationNumber}
                    onChange={(e) => setQuotationNumber(e.target.value)}
                  />

                </div>
                
                <div className='qquotition-date'>
                  <input 
                    className='qinput-field' 
                    type='date' 
                    placeholder='Quotition Date'
                    onChange={(e) => setQuotationDate(e.target.value)}
                    value={quotationDate}
                    id="quotationDate"
                  />
                </div>
                <div className='qrefrence-name'> 
                  <input 
                    className='qinput-field-ref' 
                    type='text' 
                    placeholder='Refrence Name'
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className='qsecond-row'>
              <div className='qparty-name'>
                <input 
                  className='qsecond-input' 
                  type='text' 
                  placeholder='Party Name'
                  id="partyName"
                  value={partyName}
                  onChange={(e) => setPartyName(e.target.value)}
                />
              </div>
              <div className='qcontact-no'>
                <input 
                  className='qsecond-input' 
                  type='text' 
                  placeholder='Contact No.'
                  id="contactNumber"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              <div className='qdelivery-place'>
                <input 
                  className='qsecond-input' 
                  type='text' 
                  placeholder='Delivery Place'
                  id="deliveryPlace"
                  value={deliveryPlace}
                  onChange={(e) => setDeliveryPlace(e.target.value)}
                />
              </div>
              <div className='qtrip'>
                <input 
                  className='qsecond-input' 
                  type='text' 
                  placeholder='Trip'
                  id="tripSelection"
                  value={tripSelection}
                  onChange={(e) => setTripSelection(e.target.value)}
                />
              </div>
            </div>
            <div className='qthird-row'>
              <div className='qmaterial'>
                <select 
                  className='qthird-input' 
                  type='text' 
                  placeholder='Material'
                  id="material"
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                >
                  <option value="" disabled hidden>Choose Material</option>
                  <option>Material 1</option>
                  <option>Material 2</option>
                  <option>Material 3</option>
                </select>
              </div>
              <div className='qqty'>
                <input 
                  className='qthird-input' 
                  type='text' 
                  placeholder='Qty'
                  id="qty"
                  value={qty}
                  onChange={(e) => setQty(e.target.value)}
                />
              </div>
              <div className='qrate'>
                <input 
                  className='qthird-input' 
                  type='text' 
                  id="rate"
                  placeholder="Rate"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
              <div className='qunit'>
                <select 
                  className='qthird-input' 
                  type='text' 
                  placeholder="Unit"
                  id="unit"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <option value="" disabled hidden>Choose Unit</option>
                  <option>Brass</option>
                  <option>Ton</option>
                </select>
              </div>
              <div className='qamount'>
                <input 
                  className='qthird-input' 
                  type='text' 
                  id="amount"
                  placeholder="Amount"
                  value={amount}
                  readOnly
                />
              </div>
              <div className='qadd-btn'>
                <button onClick={addItem}>Add</button>
              </div>
            </div>
          </div>
          <div className="qsecond-section">
            <div className='table'>
              <div className='main-table'>
                <div class='overflow-x-auto'>
                  <table className='t-b'>
                    <thead className='t-h'>
                      <tr className='t-r'>
                        <th className="first-th">Material</th>
                        <th className="first-th">Qty</th>
                        <th className="first-th">Rate</th>
                        <th className="first-th">Unit</th>
                        <th className="first-th">Amount</th>
                        <th className="first-th">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-data"> 
                      {items.map((item, index) => (
                        <tr className='even-colour' key={index}>
                          <td className="second-td">{item.material}</td>
                          <td className="second-td">{item.qty}</td>
                          <td className="second-td">{item.rate}</td>
                          <td className="second-td">{item.unit}</td>
                          <td className="second-td">{item.amount}</td>
                          <td className="second-td-b">
                            <button
                              type="button"
                              onClick={() => handleEdit(index)}
                              className="edit-r-icon"
                              id='edit-r-icon'
                            >
                              <FaEdit />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDelete(index)}
                              className="delete-r-icon"
                              id='delete-r-icon'
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="summary-section">
              <div className="summary-row">
                <span className="summary-label">GST(5%):</span>
                <span className="summary-value">
                  {gstAmount.toFixed(2)} INR
                </span>
              </div>
              <div className="summary-row">
                <span className="summary-label">Total Amount:</span>
                <span className="summary-value">
                  {totalAmountWithGST.toFixed(2)} INR
                </span>
              </div>
            </div>

            <div className="qdescription">
              <p className="qdescription-text">Description:</p>
              <input
                type="text"
                className="qdescription-input"
                placeholder="Enter Description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <div className="note-text">
                <p className="note">Note:</p>
                <p>Quotition Table Period Max 15 Days Valid Period.</p>
              </div>
              {/* Last Column */}
              <div className="last">
                <div className="button-last">
                  <button
                    type="submit"
                    onClick={handleClose}
                    className="submit-button"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="reset-button"
                  >
                    Submit
                  </button>
                </div>
                {message && (
                  <p
                    className="text-green-500 mt-4"
                    style={{ marginLeft: "7%" }}
                  >
                    {message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
  )
}

export default NewQuotation