import React, { useState } from 'react';
import Modal from '../components/Model';
import Login from './Login';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLoginClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-cover bg-center h-[100vh]" style={{ backgroundImage: `url('/Landing.jpeg')` }}>
      {/* Navigation Bar */}
      <div className="bg-white bg-opacity-110 py-4 px-8 flex justify-between items-center shadow-md fixed w-full z-10 height-220px">
        <div className="flex items-center">
          <h1 className="text-black text-2xl font-bold font-Poppins">
            <b>Innovativ<span style={{ color: 'rgb(234, 209, 0, 1)' }}>Soft</span></b>
          </h1>
        </div>
        <div className="flex items-center space-x-8">
          {/* <a href="#home" className="text-black text-lg">Home</a>
          <a href="#about" className="text-black text-lg">About Us</a>
          <a href="#contact" className="text-black text-lg">Contact Us</a>
          <span className="text-black text-lg">+918780654812</span> */}
          <button
            onClick={handleLoginClick}
            className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-6 rounded-full focus:outline-none"
          >
            Login
          </button>
          {/* <button
            onClick={handleLoginClick}
            className="bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 hover:from-blue-500 hover:to-purple-500 text-white font-bold py-2 px-6 rounded-full focus:outline-none"
          >
            Forgot Username/Password
          </button> */}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex items-center justify-center h-full text-left">
        <div className="text-center text-white p-8 w-full max-w-xl">
          <button className="bg-gradient-to-r from-blue-500 to-purple-900 text-white font-bold py-2 px-11 rounded-full focus:outline-none mb-6">
            10+ Happy & Satisfied Clients
          </button>
          <h1 className="text-4xl font-bold mb-6 leading-tight font-Montserrat">
            Web based billing software for <br /> Stone Crusher.
          </h1>
          <p className="text-2xl mb-8 font-montserrat">
            You can use this on any device, anywhere, anytime via the internet.
          </p>
        </div>
      </div>

      {/* Modal for Login */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Login />
      </Modal>
    </div>
  );
};

export default Home;
